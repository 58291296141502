<template>
  <div class="tablebody">
    <div class="info">
      <div class="paycount" id="paycount1">{{totalRows}}</div>
      <b-tooltip target="paycount1">Количество сервисов</b-tooltip>
      <b-btn class='paycount' size="sm" v-on:click="querytabledata"> <b-icon-arrow-repeat> </b-icon-arrow-repeat> </b-btn>
    </div>
    <b-container fluid>
      <b-row>
        <b-col md="3" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Поиск" size="sm" style="background-color: #c6ced4" />
              <b-input-group-append>
                <b-btn size="sm" :disabled="!filter" @click="filter = ''"><b-icon-backspace></b-icon-backspace></b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" class="my-1">
          <b-input-group v-if="isOwner||isBank">
            <b-form-select v-model="supplier" :options="suppliers" size="sm" style="background-color: #c6ced4" >
              <template slot="first">
                <option :value="null" >-- Поставщик --</option>
              </template>
            </b-form-select>
            <b-btn size="sm" :disabled="!supplier" @click="supplier = null" ><b-icon-backspace></b-icon-backspace></b-btn>
          </b-input-group>
        </b-col>
        <b-col md="4">
          <b-form-radio-group v-model="selected"
                            :options="options"
                            name="radioInline"
                            size="sm"
                            style="margin-top: 5px">
          </b-form-radio-group>
        </b-col>
      </b-row>
      <b-table striped hover small show-empty
                table-variant="dark"
                style="color: #ccc"
                stacked="md"
                :items="filterItems"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
      >
      </b-table>
      <b-row>
        <b-col md="6" class="my-1">
          <b-pagination pagination-sm :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import config from '../_services/config.json';

export default {
  name: 'services',
  data: () =>  ({
    isBusy: false,
    items: [],
    fields: [
      //{ label: '#', key: 'index', 'class': 'text-center'},
      { label: 'ID', key: "id", sortable: true, 'class': 'text-left' },
      { label: 'Наименование', key: "name", sortable: true, 'class': 'text-left' },
      { label: 'Поставщик', key: "supplier", 'class': 'text-left' },
      { label: 'Тип сервиса', key: "servicetype", 'class': 'text-left' },
      { label: 'Категория', key: "category", sortable: true, 'class': 'text-left' },
      { label: 'Вознаграждение', key: "percent", 'class': 'text-right' },
      { label: 'Комиссия', key: "comission", 'class': 'text-right' },
      { label: 'Маска', key: "validation", 'class': 'text-left' },
      { label: 'Протокол', key: "protocol", 'class': 'text-left' }
    ],
    selected: 'false',
    options: [
      { text: 'Включенные', value: 'false' },
      { text: 'Выключенные', value: 'true' },
      { text: 'Удаленные', value: '' }
    ],
    currentPage: 1,
    perPage: 40,
    totalRows: 0,
    activeServices: '',
    totalsum: 0,
    pageOptions: [ 20, 40, 80 ],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    reqtype: [],
    supplier:null,
    suppliers:[],
  }),
  computed: {
    isOwner () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Владелец'
      else return false
    },
    isBank () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Банк'
      else return false
    },
    sortOptions () {
    // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
    filterItems() {
      const filteredItems = this.items.filter(item => ((this.supplier==null ? this.supplier: item.supplierid)==this.supplier));
      this.onFiltered (filteredItems);
      return filteredItems;
    },
  },
  methods: {
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getSuppliers () {
      axios
      .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid,
                                                    partnertype: this.$store.state.authentication.user.partnertype,
                                                    bankId: '',
                                                    type: ['Suppliers'], status: false,
                                                    token: this.$store.state.authentication.user.token})
      .then(response => {
        this.suppliers = response.data;
        this.suppliers.forEach((item,index) => {
          Object.assign(this.suppliers[index], {value:item.id}, {text: item.name})
        })
      })
      .catch(error => {
        if (error.response.status === 401) {
            // auto logout if 401 response returned from api
            this.$store.dispatch('authentication/logout');
            this.$router.push('/')
        } else alert(error)
      });
    },
    expandAdditionalInfo(row) {
      //row.toggleDetails
      row._showDetails = !row._showDetails;
    },
    querytabledata () {
      if (this.$store.state.authentication.user.partnertype==='Дилер'||this.$store.state.authentication.user.partnertype==='Субдилер')
        this.reqtype=['DealerServices'];
      else
        this.reqtype=['Services'];
      axios
          .post(`${config.apiUrl}/query/getdata`,{ DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid, status: this.selected, type: this.reqtype,
                                                    token: this.$store.state.authentication.user.token})
          .then(response => {
                  this.items = response.data;
                  this.totalRows = this.items.length;
                })
          .catch(error => {
            if (error.response.status === 401) {
                // auto logout if 401 response returned from api
                this.$store.dispatch('authentication/logout');
                this.$router.push('/')
            } else alert(error)
          });
    }
  },
  watch: {
    selected: function () {
      this.querytabledata()
    }
  },
  created() {
    this.querytabledata ();
    this.getSuppliers ();
  }
}
</script>
<style scoped>
.tablebody{
  background-color: #21252b;
  font-size: 14px;
}
.info {
  display: flex;
  padding: 10px;
  justify-content: center;

}
.paycount {
  display: block;
  width: auto;
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  background-color: #6c757d;
  color: orange;
  border-radius: 3px;
}
</style>
